<template>
  <div class="row pt-4 mt-3">
    <div class="col-lg-3">
      <h2 class="h4">Account details</h2>
    </div>
    <div class="col-lg-9">
      <div class="border rounded-3 p-3" id="auth-info">
        <!-- Name-->
        <div class="border-bottom pb-3 mb-3">
          <editable-field
            property="name"
            name="Name"
            :initial-value="userRole.name"
            endpoint="profile"
            type="text"
            @updated="reloadUser"
          ></editable-field>
        </div>
        <!-- / Name -->
        <!-- Email-->
        <div class="border-bottom pb-3 mb-3">
          <editable-field
            property="email"
            name="Email"
            :initial-value="user.email"
            endpoint="profile"
            type="email"
            @updated="reloadUser"
          ></editable-field>
        </div>
        <!-- / Email -->
        <!-- Phone-->
        <div class="border-bottom pb-3 mb-3">
          <phone-editable-field
              name="Phone"
              :phone-initial-value="user.phone"
              :phone-country-code-initial-value="user.phone_country_code"
              endpoint="profile"
              @updated="reloadUser"
          ></phone-editable-field>
        </div>
        <!-- / Phone -->
<!--        &lt;!&ndash; Phone&ndash;&gt;-->
<!--        <div class="border-bottom pb-3 mb-3">-->
<!--          <editable-field-->
<!--            property="phone"-->
<!--            name="Phone"-->
<!--            :initial-value="user.phone"-->
<!--            endpoint="profile"-->
<!--            type="tel"-->
<!--            @updated="reloadUser"-->
<!--          ></editable-field>-->
<!--        </div>-->
<!--        &lt;!&ndash; / Phone &ndash;&gt;-->
        <!-- Password-->
        <div :class="{'border-bottom pb-3 mb-3': isProvider}">
            <editable-field
            property="password"
            name="Password"
            :initial-value="user.password"
            endpoint="profile"
            type="password"
            :confirm-current="true"
            :confirmed="true"
            @updated="reloadUser"
          ></editable-field> 
        </div>
        <!-- / Password -->
        <!-- Terms -->
        <div v-if="isProvider" class="border-bottom pb-3 mb-3">
          <editable-field
              property="terms_and_conditions_url"
              name="Terms And Conditions URL"
              :initial-value="userRole.terms_and_conditions_url"
              endpoint="profile"
              type="text"
              @updated="reloadUser"
          ></editable-field>
        </div>
        <!-- / Terms -->
        <!-- Video embed url -->
        <div v-if="isProvider">
          <editable-field
              property="video_embed_url"
              name="Video Embed URL"
              :initial-value="userRole.video_embed_url"
              endpoint="profile"
              type="text"
              @updated="reloadUser"
          ></editable-field>
        </div>
        <!-- / Video embed url -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import EditableField from "../../components/Display/EditableField.vue";
import PhoneEditableField from "../../components/Display/PhoneEditableField";
export default {
  components: { EditableField, PhoneEditableField },
  computed: {
    ...mapGetters("auth", {
      user: "user",
      userRole: "userRole",
      isProvider: "isProvider"
    }),
  },
  methods: {
    ...mapActions('auth', {
      reloadUser: 'reloadUser'
    })
  }
};
</script>

<style>
</style>
<template>
  <div class="d-flex align-items-start justify-content-between pb-4 mb-2 border-bottom-md">
    <div class="d-flex align-items-start">
      <div class="position-relative flex-shrink-0">

        <input
            type="file"
            class="file-input"
            ref="fileInput"
            accept="image/*"
            @change="onFilePicked"/>

        <!--        <img-->
        <!--            class="rounded-circle"-->
        <!--            :src="avatarUrl"-->
        <!--            width="100"-->
        <!--            alt="Profile"-->
        <!--        />-->
        <user-role-avatar class="rounded-circle"
                          :user-role="userRole"
                          width="100"></user-role-avatar>
        <button
            @click.prevent="attemptChangeAvatar"
            class="
                    btn btn-icon btn-light btn-xs
                    rounded-circle
                    shadow-sm
                    position-absolute
                    end-0
                    bottom-0
                  "
            type="button"
            data-bs-toggle="tooltip"
            title=""
            data-bs-original-title="Change image"
            aria-label="Change image"
        >
          <i
              class="fi-pencil fs-xs"></i>
        </button>
      </div>
      <div class="ps-3 ps-sm-4">
        <h3 class="h5">{{ userRole.name }}</h3>
        <ul class="list-unstyled fs-sm mb-0">
          <li class="d-flex text-nav text-break">
            <i class="fi-mail opacity-60 mt-1 me-2"></i
            ><span>{{ user.email }}</span>
          </li>
          <li class="d-flex text-nav text-break">
            <i class="fi-phone opacity-60 mt-1 me-2"></i
            ><span>{{ user.phone ? user.phone : 'Not provided' }}</span>
          </li>
        </ul>
      </div>
    </div>
    <a @click.prevent="logout" class="nav-link p-0 d-none d-md-block" href="#"
    ><i class="fi-logout mt-n1 me-2"></i>Sign out</a
    >
  </div>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import Images from "../../mixins/Images";
import axios from 'axios';
import Forms from "../../mixins/Forms";

import {useToast} from "vue-toastification";
import UserRoleAvatar from "../../components/UserRoleAvatar";

const toast = useToast();

export default {
  components: {UserRoleAvatar},
  methods: {
    ...mapActions('auth', {
      logout: 'logout',
      reloadUser: 'reloadUser'
    }),

    attemptChangeAvatar() {
      this.$refs.fileInput.click();
    },

    onFilePicked(e) {
      let vm = this;
      const file = e.target.files[0];

      let formData = new FormData();
      formData.append('avatar', file);

      axios.post(window.API_BASE + '/profile/change-avatar', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(r => {
        toast.success('Avatar updated successfully');
        vm.reloadUser();
      }).catch(e => {
        vm.setAndNotifyErrors(e, 'Error updating avatar');
      });
    }
  },
  mixins: [Images, Forms],
  computed: {
    ...mapGetters('auth', {
      user: 'user',
      userRole: 'userRole'
    }),

    avatarUrl() {
      return this.getAvatarSrc(this.userRole);
    }
  }
};
</script>

<style scoped lang="scss">
.file-input {
  display: none;
}
</style>
<template>
  <div class="row pt-4 mt-3">
    <div class="col-lg-3">
      <h2 class="h4">Two factor authentication</h2>
    </div>
    <div class="col-lg-9">
      <div class="border rounded-3 p-4">
        <div class="loader" v-if="isLoading">
          <spinner></spinner>
        </div>

        <div>
          Two factor authentication (2FA) strengthens access security by requiring two methods (also referred to as
          factors) to verify your identity. Two factor authentication protects against phishing, social engineering and
          password brute force attacks and secures your login from attackers exploiting weak or stolen credentials.
        </div>

        <!-- Disabled -->
        <div v-if="!isEnabled && !isLoading">
          <a class="btn btn-primary mt-4"
             v-if="!secretCode"
             @click.prevent="generateSecretCode"
             :class="{'opacity-50': isToggling}">
            <spinner class="spinner-border-sm me-2" v-if="isToggling"></spinner>
            Enable 2FA
          </a>

          <!-- Secret code -->
          <div v-if="secretCode" class="secret-code">
            <div class="tfa-section">
              1. Scan this QR code with your Google Authenticator App. Alternatively, you can use the code:
              <b>{{ secretCode.secret_code }}</b>
            </div>

            <div class="tfa-section" v-if="qrCodeUrl">
              <qrcode-vue :size="200" :value="qrCodeUrl"></qrcode-vue>
            </div>

            <div class="tfa-section">
              <label class="form-label" for="authenticatorCode">Please enter Authenticator Code</label>
              <input v-model="authenticatorCode"
                     @keyup.enter="enable2fa"
                     type="text" class="form-control">
            </div>

            <div class="tfa-section">
              <a class="btn btn-primary"
                 @click.prevent="enable2fa"
                 :class="{'opacity-50': isToggling}">
                <spinner class="spinner-border-sm me-2" v-if="isToggling"></spinner>
                Enable 2FA
              </a>
            </div>
          </div>
          <!-- / Secret code -->
        </div>
        <!-- / Disabled -->

        <!-- Enabled -->
        <div v-if="isEnabled && !isLoading">
          <div class="tfa-section">
            <b>Two factor authentication is currently enabled.</b>
          </div>
          <div class="tfa-section">
            <a class="btn btn-danger"
               @click.prevent="disable2fa"
               :class="{'opacity-50': isToggling}">
              <spinner class="spinner-border-sm me-2" v-if="isToggling"></spinner>
              Disable 2FA
            </a>
          </div>
        </div>
        <!-- / Enabled -->
      </div>
    </div>
  </div>
</template>

<script>
import Spinner from "../../components/Spinner";
import twoFactorAuthentication from "../../api/two-factor-authentication";
import Forms from "../../mixins/Forms";
import QrcodeVue from 'qrcode.vue'
import {useToast} from 'vue-toastification';

const toast = useToast();

export default {
  name: "TwoFactorAuthentication",
  components: {Spinner, QrcodeVue},
  mixins: [Forms],
  data() {
    return {
      isLoading: false,
      isEnabled: null,

      isToggling: false,
      secretCode: null,

      authenticatorCode: ''
    }
  },
  created() {
    this.determineIf2faEnabled();
  },
  computed: {
    qrCodeUrl() {
      if (!this.secretCode) {
        return null;
      }
      return this.secretCode.qr_code_url;
    }
  },
  methods: {
    determineIf2faEnabled() {
      let vm = this;
      vm.isLoading = true;
      twoFactorAuthentication.determineIf2faEnabled().then(r => {
        vm.isEnabled = Boolean(r.data);
        vm.isLoading = false;
      }).catch(e => {
        console.log(e);
        vm.setAndNotifyErrors(e, 'Error determining if 2fa enabled');
      });
    },

    generateSecretCode() {
      let vm = this;
      vm.isToggling = true;
      twoFactorAuthentication.generate2faSecretCode().then(r => {
        vm.secretCode = r.data;
        vm.authenticatorCode = '';
        vm.isToggling = false;
      }).catch(e => {
        console.log(e);
        vm.isToggling = false;
        vm.setAndNotifyErrors(e, 'Error generating secret code');
      });
    },

    enable2fa() {
      let vm = this;
      vm.isToggling = true;
      twoFactorAuthentication.enable2fa(this.authenticatorCode).then(r => {
        vm.isToggling = false;
        if (r.data.status === 'success') {
          toast.success('Two factor authentication enabled successfully');
          vm.isEnabled = true;
          vm.authenticatorCode = '';
        } else {
          toast.error('Error enabling two factor authentication. Please try again.');
          vm.authenticatorCode = '';
        }
      }).catch(e => {
        console.log(e);
        vm.isToggling = false;
        vm.setAndNotifyErrors(e, 'Error enabling 2fa');
      });
    },

    disable2fa() {
      let vm = this;
      vm.isToggling = true;
      twoFactorAuthentication.disable2fa().then(r => {
        vm.isToggling = false;
        if (r.data.status === 'OK') {
          toast.success('Two factor authentication disabled successfully');
          vm.isEnabled = false;
        } else {
          toast.error('Error disabling two factor authentication. Please try again.');
        }
      }).catch(e => {
        console.log(e);
        vm.isToggling = false;
        vm.setAndNotifyErrors(e, 'Error enabling 2fa');
      });
    }
  }
}
</script>

<style scoped lang="scss">
.loader {
  text-align: center;
}

.tfa-section {
  margin-top: 25px;
}
</style>
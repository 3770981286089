<template>
  <div class="d-flex align-items-center justify-content-between" :class="{'py-2': isEditing}">
    <div class="pe-2 flex-grow-1" :class="{'opacity-40': isSaving}">
      <!-- Not editing -->
      <div v-if="!isEditing">
        <label class="form-label fw-bold">{{ name }}</label>
        <div>
          {{ phoneNumberToShow }}
        </div>
      </div>
      <!-- / Not editing -->

      <!-- Editing -->
      <div v-if="isEditing">
        <div class="row">
          <div class="col-lg-4">
            <label class="form-label fw-bold" for="countryCodeInput">
              Country Code
            </label>
            <select v-model="newPhoneCountryCode" class="form-control">
              <option v-for="(label, countryCode) in countryCodeOptions"
                      :key="countryCode"
                      :value="countryCode">
                {{ label }}
              </option>
            </select>
          </div>
          <div class="col-lg-8">
            <label class="form-label fw-bold" for="phoneNumberInput">
              Phone Number
            </label>
            <input
                id="phoneNumberInput"
                @keyup.enter="attemptSave"
                type="tel"
                v-model="newPhone"
                class="form-control"
            />
          </div>
        </div>
      </div>
      <!-- / Editing -->
    </div>

    <!-- Not editing -->
    <div v-if="!isEditing" class="me-n3" aria-label="Edit">
      <a @click.prevent="edit" class="nav-link py-0" href="#"
      ><i class="fi-edit"></i
      ></a>
    </div>
    <!-- / Not editing -->

    <!-- Editing -->
    <div v-if="isEditing" class="actions-wrapper" aria-label="Save">
      <button
          v-if="!isSaving"
          type="button"
          class="btn btn-danger btn-sm mx-2"
          @click.prevent="cancel"
      >
        Cancel
      </button>

      <button
          v-if="!isSaving"
          type="button"
          class="btn btn-primary btn-sm"
          @click.prevent="attemptSave"
      >
        Save
      </button>

      <button
          v-if="isSaving"
          type="button"
          class="btn btn-primary btn-sm"
          :disabled="isSaving"
      >
        <span
            class="spinner-border spinner-border-sm me-2"
            role="status"
            aria-hidden="true"
        ></span>
        Saving
      </button>
    </div>
    <!-- / Editing -->
  </div>
</template>

<script>
import axios from "axios";
import Forms from "@/mixins/Forms";

import {useToast} from "vue-toastification";
const countryCodes = require('country-codes-list')

const toast = useToast();

export default {
  props: ["name", "phoneInitialValue", "phoneCountryCodeInitialValue", "endpoint"],
  mixins: [Forms],
  data() {
    return {
      isEditing: false,

      currentPhoneCountryCode: null,
      currentPhone: null,

      newPhoneCountryCode: null,
      newPhone: null,

      isSaving: false,
      serverErrors: [],
    };
  },
  computed: {
    phoneNumberToShow() {
      if (this.currentPhoneCountryCode && this.currentPhone) {
        return '+' + (this.currentPhoneCountryCode ? this.currentPhoneCountryCode : '44') + ' ' + this.currentPhone
      }
      return 'Not provided';
    },

    countryCodeOptions() {
      return countryCodes.customList('countryCallingCode', '+{countryCallingCode} - {countryNameEn}')
    }
  },
  methods: {
    edit() {
      this.isEditing = true;
      this.newPhone = this.currentPhone;
      this.newPhoneCountryCode = this.currentPhoneCountryCode;
    },

    cancel() {
      this.isEditing = false;
      this.newPhone = null;
      this.newPhoneCountryCode = null;
    },

    attemptSave() {
      this.save();
    },

    save() {
      this.serverErrors = [];
      this.isSaving = true;

      let params = {};
      params['phone'] = this.newPhone;
      params['phone_country_code'] = this.newPhoneCountryCode;
      axios
          .put(window.API_BASE + "/" + this.endpoint, params)
          .then(() => {
            this.currentPhone = this.newPhone;
            this.currentPhoneCountryCode = this.newPhoneCountryCode;
            this.newPhone = null;
            this.newPhoneCountryCode = null;
            this.isSaving = false;
            this.isEditing = false;
            toast.success(this.name + " saved successfully!");
            this.$emit("updated");
          })
          .catch((e) => {
            console.log(e);
            this.isSaving = false;
            this.setAndNotifyErrors(e, "Error saving new " + this.name);
          });
    },
  },
  created() {
    this.currentPhone = this.phoneInitialValue;
    this.currentPhoneCountryCode = this.phoneCountryCodeInitialValue;
  },
};
</script>

<style scoped>
.actions-wrapper {
  padding-top: 29px;
  min-width: 165px;
}

.btn.btn-sm {
  padding-top: 0.63rem;
  padding-bottom: 0.63rem;
}

.textarea-display {
  display: block;
  white-space: pre-line;
  padding-top: 10px;
}
</style>
<template>
  <div>
    <dark-header-background></dark-header-background>
    <div class="container content-overlay mt-5 mb-md-4 py-5">
      <breadcrumb class="mb-3 mb-md-4 pt-md-3" type="light" :crumbs="crumbs"></breadcrumb>

      <!-- Card -->
      <div class="bg-light shadow-sm rounded-3 p-4 p-md-5 mb-2">
        <profile-header></profile-header>
        <account-details></account-details>
        <two-factor-authentication></two-factor-authentication>
      </div>
      <!-- / Card -->
    </div>
  </div>
</template>

<script>
import Breadcrumb from '../components/Breadcrumb.vue'
import DarkHeaderBackground from "../components/DarkHeaderBackground.vue"
import AccountDetails from './Profile/AccountDetails.vue'
import ProfileHeader from "./Profile/ProfileHeader.vue"
import TwoFactorAuthentication from "./Profile/TwoFactorAuthentication";

export default {
  components: {TwoFactorAuthentication, DarkHeaderBackground, Breadcrumb, ProfileHeader, AccountDetails},
  data() {
    return {
      crumbs: [
        {
          href: '/',
          label: 'Home'
        },
        {
          href: '#',
          label: 'Your Profile',
          active: true
        }
      ]
    }
  }
}
</script>

<style>
</style>
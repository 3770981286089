import axios from 'axios';

export default {
    determineIf2faEnabled() {
        return axios.post(window.API_BASE + '/2fa/is-enabled');
    },

    generate2faSecretCode() {
        return axios.post(window.API_BASE + '/2fa/generate-secret-code');
    },

    enable2fa(verifyCode) {
        return axios.post(window.API_BASE + '/2fa/enable', {
            verify_code: verifyCode
        });
    },

    disable2fa() {
        return axios.post(window.API_BASE + '/2fa/disable');
    },

    verifyOtp(token) {
        return axios.post(window.API_BASE + '/2fa/verify', {
            token: token
        });
    }
}